export async function useRegistrationRequestList(options?: {
  session_id?: DatabaseTable<"course_sessions">["id"];
  status?: DatabaseEnum<"course_session_registration_request_status">[];
}) {
  const { profile } = useOrganizationMemberProfile();

  const { data: list, refresh } = await useLazyFetch(
    `/api/organizations/${profile.value?.organization_id}/requests`,
    {
      query: {
        session_id: options?.session_id,
        status: options?.status,
      },
      transform(data) {
        return (
          data?.sort((request) => {
            if (request.member.user_id === profile.value?.user_id) return -1;

            return 1;
          }) ?? []
        );
      },
    },
  );

  const memberIds = computed(
    () => list.value?.map((item) => item.member.id) ?? [],
  );

  return {
    list,
    refresh,
    memberIds,
  };
}
